/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Animate CSS */
@import "~animate.css/animate.min.css";

/* Fonts */
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif;
    --keyboard-offset: 0px !important;

    &:focus:active {
        box-shadow: none;
    }
}

// components

.simple-select {
    border: 2px solid var(--light-grey);
    text-align: center;
    border-radius: 10px;
    padding: 14px 20px;
    line-height: 24px;
    background-color: #ffffff;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: var(--light-grey);

    .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        margin: 0 auto;
        text-transform: capitalize;
    }

    .icon {
        position: absolute;
        top: 16px;
        right: 30px;
        font-size: 20px;
    }

    &.transparent {
        background-color: transparent;
        border: 2px solid #ffffff;
        color: #ffffff;
    }
}

.btn--mint {
    transition: 0.3s;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: var(--dark-mint);
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;

    .icon {
        position: absolute;
        top: 15px;
        right: 30px;
        font-size: 20px;
        background-color: #ffffff;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        color: var(--black);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.disabled {
        opacity: 0.3;
    }
}

.btn--light-blue {
    transition: 0.3s;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: var(--water-blue);
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    color: #1c3757;
}

.btn--transparent-light-blue {
    transition: 0.3s;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: transparent;
    color: var(--water-blue);
    font-size: 16px;
    font-weight: 500;
    border: 2px solid var(--water-blue);
}

.btn--mint-light {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid var(--dark-mint);
    color: var(--black);
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.btn--transparent {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    color: var(--black);
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

.btn--mint-light {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid var(--dark-mint);
    color: var(--black);
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.btn--dark-blue {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    color: #ffffff;
    background-color: var(--dark-blue);
    font-size: 20px;
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
}

.btn--white-light {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
}

.btn--option {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: 2px solid var(--black);
    color: var(--black);
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;

    &.selected {
        border: 2px solid var(--dark-mint);
        background-color: var(--dark-mint);
        color: #ffffff;
    }
}

.btn--close-modal {
    font-size: 32px;
    border-radius: 10px;
    color: #302334;
    background-color: #f2f2f2;
    position: absolute;
    top: 60px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.btn--modal-nav {
    width: 52px;
    height: 52px;
    font-size: 34px;
    border-radius: 10px;
    color: #302334;
    background-color: #f2f2f2;
    position: absolute;
    top: 20px;
    left: 20px;
}
.btn--gold {
    width: 100%;
    height: 60px;
    border-radius: 50px;
    border: 2px solid #ffca1c;
    color: #000;
    background-color: #ffca1c;
    font-size: 20px;
    font-weight: 500;
}

.btn--small {
    font-size: 12px;
    height: 40px;
}

.form {
    padding: 0;
    text-align: left;

    .input {
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: 1px solid var(--light-grey);
        line-height: 24px;

        &::placeholder {
            --placeholder-color: rgba($color: #000000, $alpha: 0.5);
        }
    }
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 992px) {
    .hide-on-large {
        display: none;
    }
}
